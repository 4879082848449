import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { customer01, customer02, customer03, customer04, customer05 } from './imports'
import { Article } from '../article';
import './customer.css'

export const Customer = () => {
   return (
      <Container>
         
         <div className="customer section__padding" id="customer">
            <div className="customer-heading">
               <h1 className="gradient__text">A lot is happening,<br /> to help our delighted customers and partners.</h1>
            </div>

            <div className="customer-container">
               <div className="customer-container_group">
                  <Article imgUrl={customer01} date="Sep 26, 2021" title="TransformAI and Open  AI is the future. Let us exlore how it is?" />
                  <Article imgUrl={customer02} date="Sep 26, 2021" title="TransformAI and Open  AI is the future. Let us exlore how it is?" />
                  <Article imgUrl={customer03} date="Sep 26, 2021" title="TransformAI and Open  AI is the future. Let us exlore how it is?" />
                  <Article imgUrl={customer04} date="Sep 26, 2021" title="TransformAI and Open  AI is the future. Let us exlore how it is?" />
                  <Article imgUrl={customer05} date="Sep 26, 2021" title="TransformAI and Open  AI is the future. Let us exlore how it is?" />
               </div>
            </div>
         </div>
      </Container>
   )
};

