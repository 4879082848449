const logotext = "INGIN AI AI";
const meta = {
    title: "INGIN AI",
    description: "A data science company _ based in Riyadh, Saudi Arabia",
};

const introdata = {
    title: "Revolution",
    animated: {
        first: "Embracing the transformative power of the steam engine has propelled industries to unprecedented growth and success",
        second: "In today's data-driven landscape, fueled by AI breakthroughs and sustained growth, humanity has embarked on a new chapter of progress.",
        third: "Experience the boundless scale of innovation and unlock your potential by embracing data-driven insights and AI implementation.",
    },
    description: "INGIN AI stands as your trusted partner, empowering you to unlock the full potential of data-driven insights and AI technologies, enabling transformative growth, informed decision-making, and unparalleled market success.",
    engine: "./assets/images/engine.jpg",
};

const dataabout = {
    title: "abit about my self",
    aboutme: "With deep knowledge and experience in data, our team designed the set of services ",
};

const mindset = {
    title: "Data mindset",
    aboutme: "As data consulting firm, we makes decisions based on data analysis instead of emotion and intuition, for the pursuit of a deeper understanding of our customer behavior helping us understanding gaps, strategize and apply next business actions, and monitor progress for better control.",
};

const services = [{
    title: "Data strategy",
    description: "Change is the only that's constant. At INGIN AI, we account for internal and external factors to design an effective and feasaible data strategy. \
    INGIN AI will enable and provide you  with the right toolsets and process to measure your maturatiy level and apply needed actions to reach your business strategic goals.",
},
{
    title: "Data management & governance",
    description: "Internally, any data smokes result to business fires. INGIN AI team has deep expertise in desigining data managements policies and procedures to estabilish clear ownerhsip and accountability helping organization to streamline their operating model in alignement with business expectations.",
},
{
    title: "Data infrastructure & MLOPS",
    description: "Automation is the heart of an efficent business. INGIN AI belives in solid infrastructure setup and setting to speed up innovation and bridge the gap between engineers and scientists. MLOPS is the most vibrant landscape as if today that will shape the future of data infrastrcuture by embedding automation at the heart of ML process.",
},
{
    title: "Data analytical usecases",
    description: "Data is the prime source of business intuition. INGIN AI design and tailor analytical use cases to solve complex problem and generate insight powering decision makers and guide them through their joureny.",
},
];

const worktimeline = [{
    jobtitle: "Designer of week",
    where: "YAdfi",
    date: "2020",
},
{
    jobtitle: "Designer of week",
    where: "Jamalya",
    date: "2019",
},
{
    jobtitle: "Designer of week",
    where: "ALquds",
    date: "2019",
},
];

const techStack = [
    {
        name: "Informatica",
        value: 90,
    },
    {
        name: "Cloudera",
        value: 90,
    },
    {
        name: "Databricks",
        value: 85,
    },
    {
        name: "AWS",
        value: 92,
    },
    {
        name: "Oracle",
        value: 85,
    },
    {
        name: "Google Cloud",
        value: 60,
    },
];



const dataportfolio = [{
    img: "https://picsum.photos/400/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/800/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/600/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/300/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/700/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},

{
    img: "https://picsum.photos/400/600/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/300/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/550/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
{
    img: "https://picsum.photos/400/700/?grayscale",
    desctiption: "The wisdom of life consists in the elimination of non-essentials.",
    link: "#",
},
];

const contactConfig = {
    YOUR_EMAIL: "info@ingin.ai",
    // YOUR_FONE: "",
    description: "Reach us anytime to discuss potential collaborations for designing the next gen of AI solution.",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com",
    facebook: "https://facebook.com",
    linkedin: "https://www.linkedin.com/company/INGIN AI",
    twitter: "https://twitter.com",
    youtube: "https://youtube.com",
    twitch: "https://twitch.com",
};
export {
    meta,
    dataabout,
    mindset,
    dataportfolio,
    worktimeline,
    techStack,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,

};
