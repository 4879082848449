import React from 'react';
import logo from '../../assets/images/ingin.png';
import './footer.css'
import { introdata } from '../../content_option';


export const Footer = () => {
   return (
      <div className="footer section__padding">


         <div className="footer-links">
            <div className="footer-links_logo">
               <figure>
                  <img style={{width: "60%", height: "auto"}} src={logo} alt="Footer logo" />
                  <address>
                     <p>Ingin AI, All Rights Reserved</p>
                  </address>
               </figure>
            </div>

            <div className="footer-links_div">
               <h4>About US</h4>
               <p className="text-justify" style={{lineHeight:"2em"}}>
                  {introdata.description}
               </p>
            </div>

            <div className="footer-links_div">
               <h4>Get in touch</h4>
               <p>Riyadh, Saudi Arabia <a href="email">ingin.ai</a></p>
            </div>
         </div>
         <div className="footer-copyright">
            <p>© 2020 Ingin AI. All rights reserved.</p>
         </div>
      </div>
   )
};